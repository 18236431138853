import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { WagmiProvider } from 'wagmi';

import './styles/index.scss';
import { App } from './App';
import { wagmiConfig } from './config/wagmi';
import reportWebVitals from './reportWebVitals';
import Store, { StoreProvider } from './store';
import { isDefined } from './utilities/types';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
const root = isDefined(rootElement) ? ReactDOM.createRoot(rootElement) : null;

const store = new Store();

root?.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WagmiProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
