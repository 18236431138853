export class AppError extends Error {
  displayMessage: string;

  constructor(message: string, displayMessage: string) {
    super(message);
    this.displayMessage = displayMessage;
  }
}

export class ConnectionError extends AppError {
  constructor(message: string, displayMessage: string) {
    super(message, displayMessage);
    this.name = 'ConnectionError';
  }
}

export class ContractError extends AppError {
  constructor(message: string, displayMessage: string) {
    super(message, displayMessage);
    this.name = 'ContractError';
  }
}

export class ChainError extends AppError {
  constructor(message: string, displayMessage: string) {
    super(message, displayMessage);
    this.name = 'ChainError';
  }
}
