import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as dateFns from 'date-fns';
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';
import { readContract, writeContract } from 'wagmi/actions';

import { Spinner } from '~components/spinner';
import { wagmiConfig } from '~config/wagmi';
import { ERC20_TOKEN_ABI } from '~constants/abi/erc20-token.abi';
import { VESTING_ABI } from '~constants/abi/vesting.abi';
import { VESTING_CONTRACT_ADDRESS } from '~constants/env';
import { useStore } from '~store/index';
import { FeedbackModalMessageType } from '~types/layout';
import { type VestingListItem } from '~types/web3';
import { isDefined } from '~utilities/types';

import { type TokenInfo } from './vesting-item.types';

interface VestingItemProps {
  vesting: VestingListItem;
  index: number;
}

export const VestingItem: React.FC<VestingItemProps> = ({ vesting, index }) => {
  const { address } = useAccount();

  const { layoutStore } = useStore();

  const claimingAmountPullingInterval = useRef<NodeJS.Timeout>();

  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);
  const [claimingAmount, setClaimingAmount] = useState<bigint | null>(null);

  const getClaimingAmount = useCallback(async () => {
    if (!isDefined(address)) return;
    try {
      const response = await readContract(wagmiConfig, {
        abi: VESTING_ABI,
        address: VESTING_CONTRACT_ADDRESS,
        functionName: 'getClaimAmount',
        args: [address, BigInt(index)],
      });
      console.log(response);
      setClaimingAmount(response);
    } catch (error) {
      console.error('getClaimingAmount: ', error);
    }
  }, [address, index]);

  const getTokenInfo = useCallback(async () => {
    try {
      const [tokenDecimalsResponse, tokenSymbolResponse] = await Promise.all([
        readContract(wagmiConfig, {
          abi: ERC20_TOKEN_ABI,
          address: vesting.token,
          functionName: 'decimals',
        }),
        readContract(wagmiConfig, {
          abi: ERC20_TOKEN_ABI,
          address: vesting.token,
          functionName: 'symbol',
        }),
      ]);

      setTokenInfo({ symbol: tokenSymbolResponse, decimals: tokenDecimalsResponse });
    } catch (error) {
      console.error('getTokenInfo: ', error);
    }
  }, [vesting]);

  const handleClaim = useCallback(async () => {
    try {
      setIsClaimLoading(true);
      await writeContract(wagmiConfig, {
        abi: VESTING_ABI,
        address: VESTING_CONTRACT_ADDRESS,
        functionName: 'withdraw',
        args: [BigInt(index)],
      });
      await getClaimingAmount();
      layoutStore.openFeedbackModal({ type: FeedbackModalMessageType.Success, message: 'Claim successful' });
    } catch (error) {
      layoutStore.openFeedbackModal({ type: FeedbackModalMessageType.Error, message: 'Claim failed' });
    } finally {
      setIsClaimLoading(false);
    }
  }, [layoutStore, index, getClaimingAmount]);

  useEffect(() => {
    void getTokenInfo();
  }, [getTokenInfo]);

  useEffect(() => {
    void getClaimingAmount();
    clearInterval(claimingAmountPullingInterval.current);
    claimingAmountPullingInterval.current = setInterval(getClaimingAmount, 10000);
  }, [getClaimingAmount]);

  useEffect(() => {
    return () => {
      clearInterval(claimingAmountPullingInterval.current);
    };
  }, []);

  return (
    <div className="flex w-full flex-col rounded p-6 shadow-card">
      {!isDefined(tokenInfo) && <Spinner className="m-auto size-[170px] text-accent xl:size-12" />}
      {isDefined(tokenInfo) && (
        <div className="grid grid-cols-2 gap-4 xl:grid-flow-col xl:grid-cols-5">
          <div className="flex flex-col text-sm md:text-base">
            <span className="mb-2 font-space-mono font-bold uppercase text-dark-blue">[Amount]</span>
            <span>
              {formatUnits(vesting.amount, tokenInfo.decimals)} {tokenInfo.symbol}
            </span>
          </div>
          <div className="flex flex-col text-sm md:text-base">
            <span className="mb-2 font-space-mono font-bold uppercase text-dark-blue">[Start date]</span>
            <span>{dateFns.format(dateFns.fromUnixTime(Number(vesting.startTime)), 'dd.MM.yyyy')}</span>
          </div>
          <div className="flex flex-col text-sm md:text-base">
            <span className="mb-2 font-space-mono font-bold uppercase text-dark-blue">[Claim Amount]</span>
            <span>
              {isDefined(claimingAmount) ? (
                `${formatUnits(claimingAmount, tokenInfo.decimals)} ${tokenInfo.symbol}`
              ) : (
                <Spinner className="h-auto w-3 xl:w-4" />
              )}
            </span>
          </div>
          <div className="flex flex-col text-sm md:text-base">
            <span className="mb-2 font-space-mono font-bold uppercase text-dark-blue">[End Date]</span>
            <span>{dateFns.format(dateFns.fromUnixTime(Number(vesting.endTime)), 'dd.MM.yyyy')}</span>
          </div>

          <button
            className="flex items-center justify-center rounded-sm border border-accent px-6 py-3 font-medium leading-4 text-accent shadow-button transition-all hover:bg-accent hover:text-white active:opacity-80 disabled:pointer-events-none disabled:opacity-50 max-xl:col-span-2"
            onClick={handleClaim}
            disabled={isClaimLoading || !isDefined(claimingAmount) || claimingAmount === 0n}
          >
            {isClaimLoading && <Spinner className="mr-2 size-4" />}
            Claim
          </button>
        </div>
      )}
    </div>
  );
};
