import { useEffect } from 'react';

import { isDefined } from '~utilities/types';

export function useScrollLock(flag: boolean): void {
  useEffect(() => {
    const rootElement = document.getElementById('__next');
    if (flag && isDefined(rootElement)) {
      rootElement.style.overflow = 'hidden';
    } else if (isDefined(rootElement)) {
      rootElement.style.overflow = 'visible';
    }

    return () => {
      if (isDefined(rootElement)) rootElement.style.overflow = 'visible';
    };
  }, [flag]);
}
