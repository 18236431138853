'use client';

import React from 'react';

import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import ReactDOM from 'react-dom';

import { CloseIcon } from '~assets/icons';
import { useScrollLock } from '~hooks/use-scroll-lock.hook';
import { isDefined } from '~utilities/types';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  overlayClassName?: string;
  onClose?: () => void;
  lockScroll?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  className,
  overlayClassName,
  onClose,
  lockScroll = true,
}) => {
  useScrollLock(lockScroll && isOpen);

  if (typeof document === 'undefined') return <></>;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modalOverlay"
          className={cx(
            'fixed flex-1 left-0 top-0 size-full overflow-auto z-20 flex flex-col justify-start items-center py-16 bg-black/20 xl:py-24 backdrop-blur-sm',
            overlayClassName
          )}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <motion.div
            key="modalContent"
            className={cx(
              'relative max-w-[420px] mx-4 w-full rounded py-16 px-10 shadow-card m-auto bg-white',
              className
            )}
            variants={{
              hide: { transform: 'translateY(20px)' },
              show: { transform: 'translateY(0px)' },
            }}
          >
            {children}
            {isDefined(onClose) && (
              <button className="absolute right-2 top-2 text-dark" onClick={onClose}>
                <CloseIcon className="h-auto w-8" />
              </button>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
