import { Wallets } from '~constants/wallets';

export const buildOpenDappDeepLink = (params: { wallet: Wallets }): string => {
  switch (params.wallet) {
    case Wallets.MetaMask:
      return `https://metamask.app.link/${window.location.hostname}${window.location.search}&platform=metamask`;
    default:
      throw new Error('[buildOpenDappDeepLink] wallet is not supported');
  }
};
