import React from 'react';

import LayoutStore from './layoutStore';

interface StoreProviderProps extends React.PropsWithChildren {
  store: RootStore;
}

export default class RootStore {
  layoutStore: LayoutStore;

  constructor() {
    this.layoutStore = new LayoutStore(this);
  }
}

// @ts-expect-error: Always initialized after
export const StoreContext = React.createContext<RootStore>();

export const StoreProvider: React.FC<StoreProviderProps> = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = (): RootStore => React.useContext(StoreContext);
