import React from 'react';

import cx from 'classnames';

interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <svg className={cx('size-16 spinner-circle', className)} viewBox="0 0 50 50" fill="none">
      <circle cx="25" cy="25" r="20" stroke="currentColor" strokeWidth="3" />
    </svg>
  );
};
