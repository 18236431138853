import { makeAutoObservable } from 'mobx';

import { type FeedbackModalMessage } from '~types/layout';

import type RootStore from './';

export default class LayoutStore {
  rootStore: RootStore;

  feedbackModal: FeedbackModalMessage | null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'layoutStore' });
    this.rootStore = rootStore;
    this.feedbackModal = null;
  }

  openFeedbackModal(props: FeedbackModalMessage): void {
    this.feedbackModal = props;
  }

  closeFeedbackModal(): void {
    this.feedbackModal = null;
  }
}
