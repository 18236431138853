import { useConnect, type Connector } from 'wagmi';

import { MetaMaskIcon, WalletConnectIcon } from '~assets/icons/wallets';
import { Wallets } from '~constants/wallets';
import { type ConnectorOption } from '~types/web3';
import { isDefined } from '~utilities/types';

export const useConnectors = (): {
  connectors: Partial<Record<Wallets, Connector>>;
  connectorsOptions: ConnectorOption[];
} => {
  const { connectors: _connectors } = useConnect();

  const connectors: Record<Wallets, Connector> = {
    [Wallets.WalletConnect]: _connectors.find((connector) => connector.id === Wallets.WalletConnect) ?? _connectors[0],
    [Wallets.MetaMask]: _connectors.find((connector) => connector.id === 'io.metamask') ?? _connectors[0],
  };

  const connectorsOptions: ConnectorOption[] = [
    {
      id: Wallets.WalletConnect,
      name: connectors.walletConnect.name,
      logo: WalletConnectIcon,
      ready: true,
      connector: connectors.walletConnect,
    },
    {
      id: Wallets.MetaMask,
      name: connectors.metaMask.name,
      logo: MetaMaskIcon,
      ready: isDefined(window.ethereum),
      connector: connectors.metaMask,
      fallbackLink: 'https://metamask.io/download/',
    },
  ];

  return { connectors, connectorsOptions };
};
