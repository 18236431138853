import { fallback } from 'viem';
import { http, createConfig } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { walletConnect } from 'wagmi/connectors';

import { WALLETCONNECT_PROJECT_ID } from '~constants/env';

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig;
  }
}

export const wagmiConfig = createConfig({
  chains: [bsc, bscTestnet],
  connectors: [walletConnect({ projectId: WALLETCONNECT_PROJECT_ID })],
  transports: {
    [bsc.id]: fallback([http()]),
    [bscTestnet.id]: fallback([http()]),
  },
});
