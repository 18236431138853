import React, { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { type Address, type Chain } from 'viem';
import { useAccount, useChains, useConnect, useDisconnect, useSwitchChain } from 'wagmi';
import { readContract } from 'wagmi/actions';

import { CloseIcon, LogoutIcon } from '~assets/icons';
import { PrivateAiLogo } from '~assets/images';
import { Modal } from '~components/modal';
import { Spinner } from '~components/spinner';
import { VestingItem } from '~components/vesting-item/vesting-item.component';
import { wagmiConfig } from '~config/wagmi';
import { VESTING_ABI } from '~constants/abi/vesting.abi';
import { CHAIN_ID, VESTING_CONTRACT_ADDRESS } from '~constants/env';
import { useConnectors } from '~hooks/use-connectors.hook';
import { type AppError, ConnectionError } from '~types/errors';
import { FeedbackModalMessageType } from '~types/layout';
import { type VestingListItem, type ConnectorOption } from '~types/web3';
import { isDefined } from '~utilities/types';
import { buildOpenDappDeepLink } from '~utilities/web3';

import { useStore } from './store';

export const App: React.FC = observer(() => {
  const chains = useChains();
  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { connectorsOptions } = useConnectors();
  const { switchChainAsync: switchChain } = useSwitchChain();
  const { isConnected, chain: currentChain, address } = useAccount();

  const [chain, setChain] = useState<Chain | null>(null);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [vestings, setVestings] = useState<Readonly<VestingListItem[]> | null>(null);

  const vestingsPullingInterval = useRef<NodeJS.Timeout>();

  const { layoutStore } = useStore();

  const handleOpenConnectModal = useCallback(() => {
    setIsConnectModalOpen(true);
  }, []);

  const handleCloseConnectModal = useCallback(() => {
    setIsConnectModalOpen(false);
  }, []);

  const handleCloseFeedbackModal = useCallback(() => {
    layoutStore.closeFeedbackModal();
  }, [layoutStore]);

  const getVestingList = useCallback(async (account: Address) => {
    try {
      const response = await readContract(wagmiConfig, {
        abi: VESTING_ABI,
        address: VESTING_CONTRACT_ADDRESS,
        functionName: 'getVestingList',
        args: [account],
      });
      setVestings(response);
    } catch (error) {
      console.error('getVestingList: ', error);
    }
  }, []);

  const navigateToWallet = (option: ConnectorOption): void => {
    if (isMobile) {
      const deepLink = buildOpenDappDeepLink({ wallet: option.id });
      window.location.assign(deepLink ?? option.fallbackLink);
    } else {
      isDefined(option.fallbackLink) && window.location.assign(option.fallbackLink);
    }
  };

  const handleConnect = async (option: ConnectorOption): Promise<void> => {
    try {
      if (!isDefined(chain)) {
        throw new ConnectionError('no chain', 'No chain specified in parameters');
      }
      setIsConnectModalOpen(false);
      layoutStore.openFeedbackModal({ type: FeedbackModalMessageType.Loading, message: 'Connecting' });
      if (!option.ready) {
        navigateToWallet(option);
        return;
      }

      await connectAsync({ connector: option.connector, chainId: chain.id as 56 | 97 });
      layoutStore.closeFeedbackModal();
    } catch (error) {
      localStorage.clear();
      const appError = error as AppError;
      layoutStore.openFeedbackModal({
        type: FeedbackModalMessageType.Error,
        message: appError.displayMessage ?? 'Connection error',
      });
    }
  };

  const switchConnectorChain = useCallback(
    async (chainId: 56 | 97): Promise<void> => {
      if (!isDefined(chain) || !isDefined(switchChain)) {
        console.error('[switchConnectorNetwork] no chain');
        return;
      }

      try {
        layoutStore.openFeedbackModal({ type: FeedbackModalMessageType.Loading, message: 'Switching network' });
        await switchChain({ chainId });
        layoutStore.closeFeedbackModal();
      } catch (error) {
        await disconnectAsync();
        localStorage.clear();
        console.error('[switchConnectorNetwork]', error);
        layoutStore.openFeedbackModal({
          type: FeedbackModalMessageType.Error,
          message: `Incorrect network, must be: ${chain.name}. Please, try to switch it manualy through your wallet app`,
        });
      }
    },
    [chain, disconnectAsync, switchChain, layoutStore]
  );

  useEffect(() => {
    if (isConnected && isDefined(chain) && isDefined(currentChain) && chain.id !== currentChain.id) {
      void switchConnectorChain(chain.id as 56 | 97);
    }
  }, [isConnected, chain, currentChain, switchConnectorChain]);

  useEffect(() => {
    if (CHAIN_ID !== '') {
      setChain(chains.find((availableChain) => availableChain.id === parseInt(CHAIN_ID)) ?? null);
    } else {
      layoutStore.openFeedbackModal({
        type: FeedbackModalMessageType.Error,
        message: 'No network specified. Required network is BSC',
      });
    }
  }, [chains, layoutStore]);

  useEffect(() => {
    if (isDefined(address)) {
      clearInterval(vestingsPullingInterval.current);

      vestingsPullingInterval.current = setInterval(() => {
        void getVestingList(address);
      }, 3000);
    }
  }, [address, getVestingList]);

  useEffect(() => {
    return () => {
      clearInterval(vestingsPullingInterval.current);
    };
  }, []);

  const whitelist = [
    '0x438cd04831bf212f8bbd1eace391f1bf4f679d79',
    '0x8cDd307272bAbC9044Bad6007A375cc76e7a36D8',
    '0x83d98947eef142c7d0d474faec7611759261d598',
    '0x139258dc573367ef5ac5b6d1a94d29de8a51ec49',
    '0x06c82647c7acb1d8523c07596b03fc349f8d9a7c',
    '0xa566771a7c01be2c4998e6b783feed9fe862cfc9',
    '0x7593bdc396eb00d774de8cb678ada4d3a1cd2712',
    '0xc0750fc70e3214be6dabb08e7fc8a4f8ed1e40ab',
    '0xe1b96cab6562a2546c017a20cf3409473f4eb4d2',
    '0x19feeaa6cdf250b4d6d44679209bfdc3279b12d4',
    '0x41cebc8e1a6a45f2569760e477183da3037eb6b6',
    '0x1ACeb2b6125a0bc4e5261AcE34f42FC011447924',
    // kol list from  https://docs.google.com/spreadsheets/d/1W1zp7t65U0tPWsdtDOvT3xLY72HLyMPgzmmd9DdJUgc/edit?gid=1356798799#gid=1356798799
    '0xc23a54DD3055aE52B09Bb820aeA11D356799C16d',
    '0x42a975604cfc44b3407a02d8068b63916f8b260a',
    '0xc78629FcE272a19B119F498284e01d3f493F61F5',
    '0xD4FAf927a92Ea70781b5433350c0C055D567d6C3',
    '0xceD3819AAD278F346CbD03F0dbe7654Bf7a17827',
    '0x86F3AaeD82b74eD05Cac01639A7eebE2bc082D9f',
    '0xB8e7cE6f9A61A011B72651c06C869bdD95Ba4810',
    '0xd2C18EDBdC50316B1a192d2BdD8ACBce68a48402',
    '0x4e3351C6Ce0478397939268E5467Da2227BE6925',
  ];

  const whiteListLowerCase = whitelist.map((address) => address.toLowerCase());

  const isWhitelisted = address != null ? whiteListLowerCase.includes(address.toLowerCase()) : false;

  return (
    <>
      <div className="flex w-full flex-1 flex-col items-center justify-center pt-16">
        <header className="fixed left-0 top-0 z-10 flex w-full items-center justify-between bg-cream-white px-4 py-2 xl:px-10 xl:py-4">
          <a
            className="grid grid-flow-col items-center gap-2 text-accent transition-all hover:opacity-70 active:opacity-80"
            href="https://www.privateai.com/"
          >
            <PrivateAiLogo className="h-12 w-auto" />

            <span className="text-md font-medium">PrivateAI.com</span>
          </a>

          {!isConnected && (
            <button
              className="flex items-center justify-center rounded-sm border border-accent px-6 py-3 font-medium leading-4 text-accent shadow-button transition-all hover:bg-accent hover:text-white active:opacity-80"
              onClick={handleOpenConnectModal}
            >
              Connect wallet
            </button>
          )}

          {isConnected && (
            <button
              className="flex items-center justify-center rounded-sm border border-accent px-6 py-3 font-medium leading-4 text-accent shadow-button transition-all hover:bg-accent hover:text-white active:opacity-80"
              onClick={() => {
                void disconnectAsync();
              }}
            >
              <LogoutIcon className="mr-2 h-auto w-6" />
              <span>
                {address?.slice(0, 4)}...{address?.slice(-4)}
              </span>
            </button>
          )}
        </header>

        <main className="mt-24 grid w-full grid-cols-1 gap-4 px-4 xl:mt-48 xl:gap-6 xl:px-10">
          <h1 className="text-lg font-bold xl:text-xl">PrivateAI Portal</h1>
          {/* {isConnected && !isDefined(vestings) && <Spinner className="m-auto h-auto w-10 text-accent" />} */}
          {isConnected &&
            isDefined(vestings) &&
            vestings.map((vesting, index) => <VestingItem vesting={vesting} key={index} index={index} />)}
          {!isConnected && (
            <div className="w-full rounded p-8 shadow-card xl:text-md">Please connect your wallet to proceed.</div>
          )}
          {isConnected && isWhitelisted && (
            <div className="w-full rounded p-8 shadow-card xl:text-md">$PGPT Unlock status: Queued for payout</div>
          )}
          {isConnected && !isWhitelisted && (
            <div className="w-full rounded p-8 shadow-card xl:text-md">Claim Not Available</div>
          )}
        </main>

        <footer className="mt-auto grid w-full grid-cols-1 items-center justify-between gap-8 px-4 py-8 md:flex xl:px-10">
          <span className="text-gray">Web3 Foundation LLC. 2024. All rights reserved.</span>

          <div className="grid w-min grid-flow-col items-center gap-4 text-accent max-[420px]:w-full max-[420px]:grid-flow-row max-[420px]:grid-cols-2">
            <a
              className="whitespace-nowrap transition-all hover:opacity-70 active:opacity-80"
              href="https://www.privateai.com/legal"
            >
              Legal
            </a>
            <a
              className="whitespace-nowrap transition-all hover:opacity-70 active:opacity-80"
              href="https://www.privateai.com/branding-guide"
            >
              Branding Guide
            </a>
            <span className="max-[420px]:hidden">|</span>
            <a
              className="whitespace-nowrap transition-all hover:opacity-70 active:opacity-80"
              href="https://www.linkedin.com/company/privateai/"
            >
              LinkedIn
            </a>
            <a
              className="whitespace-nowrap transition-all hover:opacity-70 active:opacity-80"
              href="https://twitter.com/privateaicom"
            >
              Twitter
            </a>
          </div>
        </footer>
      </div>

      <Modal
        className="grid w-full max-w-[300px] grid-cols-1 gap-4"
        isOpen={isConnectModalOpen}
        onClose={handleCloseConnectModal}
      >
        {connectorsOptions.map((option) => (
          <button
            key={option.id}
            className="flex items-center justify-start rounded-sm border border-accent px-6 py-3 text-left font-medium leading-4 text-accent shadow-button transition-all hover:bg-accent hover:text-white active:opacity-80"
            onClick={() => {
              void handleConnect(option);
            }}
          >
            <option.logo className="mr-2 h-auto w-6" />
            {option.name}
          </button>
        ))}
      </Modal>

      <Modal
        className="grid w-full max-w-[300px] grid-cols-1 place-items-center gap-4"
        isOpen={isDefined(layoutStore.feedbackModal)}
        onClose={
          layoutStore.feedbackModal?.type === FeedbackModalMessageType.Loading ? undefined : handleCloseFeedbackModal
        }
      >
        {layoutStore.feedbackModal?.type === 'loading' && <Spinner className="size-16 text-accent" />}
        {layoutStore.feedbackModal?.type === 'error' && <CloseIcon className="h-auto w-16 text-red-600" />}
        {isDefined(layoutStore.feedbackModal) && (
          <span className="text-md font-medium">{layoutStore.feedbackModal.message}</span>
        )}
      </Modal>
    </>
  );
});
