export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Pepe = 'pepe',
}

export enum ScreenOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum ScreenSize {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type ScreenDimensions = {
  width: number;
  height: number;
  orientation: ScreenOrientation;
  size: ScreenSize;
};

export type SvgIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export enum FeedbackModalMessageType {
  Success = 'success',
  Loading = 'loading',
  Error = 'error',
}

export type FeedbackModalMessage = {
  type: FeedbackModalMessageType;
  message: string;
};
